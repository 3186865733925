import React from 'react'
import { Fade } from 'react-awesome-reveal'

import ProjectIntro from '../components/ProjectIntro'
import Header from '../components/Header'
import FarmGallery from '../components/FarmGallery'
import ProjectNav from '../components/ProjectNav'

// IMAGE IMPORTS
import calOrganicHero from '../images/cal-organic/cal-organic-hero.jpg'
import calOSocial1 from '../images/cal-organic/cal-organic-1.jpg'
import calOSocial2 from '../images/cal-organic/cal-organic-2.jpg'
import calOSocial3 from '../images/cal-organic/cal-organic-3.jpg'
import calOSocial4 from '../images/cal-organic/cal-organic-4.jpg'
import calOSocial5 from '../images/cal-organic/cal-organic-5.jpg'
import calOSocial6 from '../images/cal-organic/cal-organic-6.jpg'
import calOSocial7 from '../images/cal-organic/cal-organic-7.jpg'
import calOSocial8 from '../images/cal-organic/cal-organic-8.jpg'
import calOSocial9 from '../images/cal-organic/cal-organic-9.jpg'
import calOSocial10 from '../images/cal-organic/cal-organic-10.jpg'
import calOSocial11 from '../images/cal-organic/cal-organic-11.jpg'
import calOSocial12 from '../images/cal-organic/cal-organic-12.jpg'


const calOSocial = [
    calOSocial1,
    calOSocial2,
    calOSocial3,
    calOSocial4,
    calOSocial5,
    calOSocial6,
    calOSocial7,
    calOSocial8,
    calOSocial9,
    calOSocial10,
    calOSocial11,
    calOSocial12
]

let socialContent = calOSocial.map(image =>
        <div className='vertical-gallery-block'>
            <img src={image} />
        </div>
    )

export default () => (
    <div className='page-wrapper'>
        <Header />
        <ProjectIntro
            src={calOrganicHero}
            title='Cal Organic'
            description='Cal-Organic is one of the biggest and oldest organic farms in the world. They have been family owned and operated since day one. I created content for the social media accounts while I worked on their brand. I wanted to focus on the authenticity of the brand and what it represents. I was able to go to their farm and take pictures of some of their farmers at work. I wanted to make their feed reflect their core values: their people and their veggies. I did this by highlighting people working on the farm, and of course by packing the feed with as much of their vibrant produce as possible'
        />

        <section>
            <div className='section-info'>
                <Fade direction='left' cascade='true' triggerOnce='true'>
                    <h3 className='section-title'>Social Content</h3>
                </Fade>
                <Fade direction='right' cascade='true' triggerOnce='true'>
                    <p className='section-description'>
                        Shooting food was something I got a lot of practice with in my KFC days, so I felt comfortable creating content for Cal Organic from day one. The team already had some content pillars setup, but the photography needed to be elevated to showcase the brand in a better style. I focused on using some basic photography principles to achieve this effect: lighting, composition, depth of field, and color. Their produce came with a beautiful palette built-in, so the main work was in composition – both in composing the food and the frame.
                    </p>
                </Fade>
            </div>
            
            <div className='vertical-gallery'>
                <Fade direction='top' cascade='true' triggerOnce='true'>
                    {socialContent}
                </Fade>
            </div>
        </section>

        <section>
            <div className='section-info'>
                <Fade direction='left' cascade='true' triggerOnce='true'>
                    <h3 className='section-title'>Farm Shoot</h3>
                </Fade>
                <Fade direction='right' cascade='true' triggerOnce='true'>
                    <p className='section-description'>
                        Cal Organic's brand values come down to it's produce and people. I advocated for a farm photoshoot for months, insisting that it's the best way to show off what makes Cal Organic the best. We headed from LA to the central valley at 4:00 AM to catch the sunrise on the farm, and it ended up being an awesome field trip. I spent the day shooting the fields, the farmers, and the farmers in the fields. It was a quick day trip, and I wish I had more time, but I was really pleased with how the photos turned out. Almost four years later, and they're still using photos from this shoot on their feeds.
                    </p>
                </Fade>
            </div>
            <Fade direction='top' cascade='true' triggerOnce='true'>
                <FarmGallery />
            </Fade>
        </section>
        <ProjectNav
                prev='/kfc'
                prevTitle='KFC'
                next='/mattel'
                nextTitle='Mattel'
        />
        
    </div>
)