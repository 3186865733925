import React from 'react'
import { Fade } from 'react-awesome-reveal'

import ProjectIntro from '../components/ProjectIntro'
import Header from '../components/Header'
import ProjectNav from '../components/ProjectNav'

import videoHero from '../images/video/video-hero.jpg'

export default () => (
    <div className='page-wrapper'>
        <Header />
        <ProjectIntro
            src={videoHero}
            title='Video'
            description='Back in high school, I used to make snowboarding videos with my friends every winter. There just wasn`t much else to do in Idaho, and snowboarding was my life. Along with graphic design, these videos were my first real creative outlet. We always showed them at the school talent show, and one year we even won an award at the Idaho Falls film festival. The videos were crude and our skills were mostly subpar, but I loved being behind the camera and spending hours cutting up the footage and making the titles. In college, I was able to land a job that I was totally underqualilfied for, but eventually was allowed to create some videos that brought notoriety to our small production unit — and even BYU as a whole.'
        />

        <Fade direction='top' cascade='true' triggerOnce='true'>
            <div className='video-gallery'>
                <div className='video-block'>
                    <h4>New Spice</h4>
                    <div className='video-wrapper'>
                        <iframe src="https://www.youtube.com/embed/2ArIj236UHs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <div className='video-block'>
                    <h4>Gotta Be Scholar</h4>
                    <div className='video-wrapper'>
                        <iframe src="https://www.youtube.com/embed/nYjG1llQVlA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <div className='video-block'>
                    <h4>Car Sharing Zombies</h4>
                    <div className='video-wrapper'>
                        <iframe src="https://www.youtube.com/embed/Q1HVIlXqvwM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <div className='video-block'>
                    <h4>Havoc | Party</h4>
                    <div className='video-wrapper'>
                        <iframe src="https://www.youtube.com/embed/s6AJY7ZzWZk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <div className='video-block'>
                    <h4>Havoc | Girlfriend</h4>
                    <div className='video-wrapper'>
                        <iframe src="https://www.youtube.com/embed/oCIlr_17Pyk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <div className='video-block'>
                    <h4>Havoc | Laptop</h4>
                    <div className='video-wrapper'>
                        <iframe src="https://www.youtube.com/embed/rjRNbskXkt8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <div className='video-block'>
                    <h4>Library Jimmer Session | Group Study</h4>
                    <div className='video-wrapper'>
                        <iframe src="https://www.youtube.com/embed/hSxpYGcUU7U" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <div className='video-block'>
                    <h4>Library Jimmer Session | Library Movies</h4>
                    <div className='video-wrapper'>
                        <iframe src="https://www.youtube.com/embed/5JTysCSBFCo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <div className='video-block'>
                    <h4>Library Jimmer Session | March Madness</h4>
                    <div className='video-wrapper'>
                        <iframe src="https://www.youtube.com/embed/ldx1f8j7y6Q" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <div className='video-block'>
                    <h4>Research Rescue | Episode 1</h4>
                    <div className='video-wrapper'>
                        <iframe src="https://www.youtube.com/embed/9kFlnnyMsW4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <div className='video-block'>
                    <h4>Research Rescue | Episode 2</h4>
                    <div className='video-wrapper'>
                        <iframe src="https://www.youtube.com/embed/BhTaHojfLDA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <div className='video-block'>
                    <h4>Research Rescue | Episode 3</h4>
                    <div className='video-wrapper'>
                        <iframe src="https://www.youtube.com/embed/L7NbA71vI94" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </Fade>

        <ProjectNav
            prev='/code'
            prevTitle='Code'
            next='/crack'
            nextTitle='Crack Social'
        />
        
    </div>
)