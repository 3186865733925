import React from 'react'
import { Fade } from 'react-awesome-reveal';

// COMPONENTS AND STUFF
import Header from '../components/Header'
import MenuItem from '../components/MenuItem'

// IMAGE IMPORTS
import crack from '../images/nav-heroes/crack.jpg'
import trueOrganic from '../images/nav-heroes/true-organic.jpg'
import kfc from '../images/nav-heroes/kfc.jpg'
import calOrganic from '../images/nav-heroes/cal-organic.jpg'
import mattel from '../images/nav-heroes/mattel.jpg'
import video from '../images/nav-heroes/video.jpg'
import code from '../images/nav-heroes/code.jpg'

export default () => (
    <div className='page-wrapper'>
        <Header />
            <div className='home-intro'>
                <Fade direction='top'>
                    <h2>
                        I'm Derek.
                    </h2>
                    <p>
                        I design, manage, and market digital products.
                    </p>
                    <p>
                        Plus a bunch of other stuff.
                    </p>
                </Fade>
            </div>
        <div className='menu-wrapper'>
            <Fade direction='top' delay={2000}>
                <MenuItem
                    link='/crack'
                    title='Crack'
                    src={crack}
                />
            </Fade>
                <MenuItem 
                    link='/true-organic'
                    title='True Organic'
                    src={trueOrganic}
                    id='menu-true'
                />
                <MenuItem
                    link='/kfc'
                    title='KFC'
                    src={kfc}
                />
                <MenuItem 
                    link='/cal-organic'
                    title='Cal Organic'
                    src={calOrganic}
                    id='menu-calo'
                />
                <MenuItem
                    link='/mattel'
                    title='Mattel'
                    src={mattel}
                />
                <MenuItem
                    link='/video'
                    title='Video'
                    src={video}
                />
        </div>
    </div>
)