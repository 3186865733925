import kfcSocial1 from '../images/kfc/kfc-social-1.jpg'
import kfcSocial2 from '../images/kfc/kfc-social-2.jpg'
import kfcSocial3 from '../images/kfc/kfc-social-3.jpg'
import kfcSocial4 from '../images/kfc/kfc-social-4.jpg'
import kfcSocial5 from '../images/kfc/kfc-social-5.jpg'
import kfcSocial6 from '../images/kfc/kfc-social-6.jpg'
import kfcSocial7 from '../images/kfc/kfc-social-7.jpg'
import kfcSocial8 from '../images/kfc/kfc-social-8.jpg'
import kfcSocial9 from '../images/kfc/kfc-social-9.jpg'
import kfcSocial10 from '../images/kfc/kfc-social-10.jpg'
import kfcSocial11 from '../images/kfc/kfc-social-11.jpg'
import kfcSocial12 from '../images/kfc/kfc-social-12.jpg'
import kfcSocial13 from '../images/kfc/kfc-social-13.jpg'
import kfcSocial14 from '../images/kfc/kfc-social-14.jpg'
import kfcSocial15 from '../images/kfc/kfc-social-15.jpg'
import kfcSocial16 from '../images/kfc/kfc-social-16.jpg'
import kfcSocial17 from '../images/kfc/kfc-social-17.jpg'
import kfcSocial18 from '../images/kfc/kfc-social-18.jpg'
import kfcSocial19 from '../images/kfc/kfc-social-19.jpg'
import kfcSocial20 from '../images/kfc/kfc-social-20.jpg'
import kfcSocial21 from '../images/kfc/kfc-social-21.jpg'
import kfcSocial22 from '../images/kfc/kfc-social-22.jpg'
import kfcSocial23 from '../images/kfc/kfc-social-23.jpg'
import kfcSocial24 from '../images/kfc/kfc-social-24.jpg'
import kfcSocial25 from '../images/kfc/kfc-social-25.jpg'
import kfcSocial26 from '../images/kfc/kfc-social-26.jpg'
import kfcSocial27 from '../images/kfc/kfc-social-27.jpg'
import kfcSocial28 from '../images/kfc/kfc-social-28.jpg'
import kfcSocial29 from '../images/kfc/kfc-social-29.jpg'
import kfcSocial30 from '../images/kfc/kfc-social-30.jpg'
import kfcSocial31 from '../images/kfc/kfc-social-31.jpg'
import kfcSocial32 from '../images/kfc/kfc-social-32.jpg'
import kfcSocial33 from '../images/kfc/kfc-social-33.jpg'
import kfcSocial34 from '../images/kfc/kfc-social-34.jpg'
import kfcSocial35 from '../images/kfc/kfc-social-35.jpg'
import kfcSocial36 from '../images/kfc/kfc-social-36.jpg'

const kfcSocial = [
    kfcSocial1,
    kfcSocial2,
    kfcSocial3,
    kfcSocial4,
    kfcSocial5,
    kfcSocial6,
    kfcSocial7,
    kfcSocial8,
    kfcSocial9,
    kfcSocial10,
    kfcSocial11,
    kfcSocial12,
    kfcSocial13,
    kfcSocial14,
    kfcSocial15,
    kfcSocial16,
    kfcSocial17,
    kfcSocial18,
    kfcSocial19,
    kfcSocial20,
    kfcSocial21,
    kfcSocial22,
    kfcSocial23,
    kfcSocial24,
    kfcSocial25,
    kfcSocial26,
    kfcSocial27,
    kfcSocial28,
    kfcSocial29,
    kfcSocial30,
    kfcSocial31,
    kfcSocial32,
    kfcSocial33,
    kfcSocial34,
    kfcSocial35,
    kfcSocial36
]

export default kfcSocial