import React from 'react'
import {Fade} from 'react-awesome-reveal';

import ProjectIntro from '../components/ProjectIntro'
import Header from '../components/Header'
import ProjectNav from '../components/ProjectNav'


// IMAGE IMPORTS
import crackHero from '../images/crack/crack-hero.jpg'
import paperPrototype from '../images/crack/paper-prototype.jpeg'
import paperNotes1 from '../images/crack/crack-social-paper-1.jpg'
import paperNotes2 from '../images/crack/crack-social-paper-2.jpg'
import paperNotes3 from '../images/crack/crack-social-paper-3.jpg'
import protoIo from '../images/crack/crack-proto-io.jpg'
import crackBranding from '../images/crack/crack-branding.jpg'
import personaJay from '../images/crack/crack-persona-jay.png'
import personaNikki from '../images/crack/crack-persona-nikki.png'
import paperFlow from '../images/crack/crack-social-paper-flow-1.jpg'
import paperFlow2 from '../images/crack/crack-social-paper-flow-2.jpg'
import paperFlow3 from '../images/crack/crack-social-paper-flow-3.jpg'
import paperFlow4 from '../images/crack/crack-social-paper-flow-4.jpg'
import paperFlow5 from '../images/crack/crack-social-paper-flow-5.jpg'
import paperSketch1 from '../images/crack/crack-social-paper-sketches-1.jpg'
import paperSketch2 from '../images/crack/crack-social-paper-sketches-2.jpg'
import blockframes from '../images/crack/blockframes.jpg'
import designSystem from '../images/crack/design-system.jpg'
import highFi2017 from '../images/crack/crack-high-fi-2017.jpg'
import appStore1 from '../images/crack/app-store-1.jpg'
import appStore2 from '../images/crack/app-store-2.jpg'
import appStore3 from '../images/crack/app-store-3.jpg'
import appStore4 from '../images/crack/app-store-4.jpg'
import roadmap from '../images/crack/roadmap.jpg'
import pitchDeck from '../images/crack/pitch-deck.jpg'
import highFi2019 from '../images/crack/high-fi-2019.jpg'

const crackRoles = [
    'Product Design',
    'User Research',
    'Product Management',
    'Marketing',
    'Business Development',
    'Branding Design'
]

let roles = crackRoles.map(role => 
        <h5>{role}</h5>
    )

export default () => (
    <div className='page-wrapper'>
        <Header />
                <ProjectIntro
                    id='crack-intro'
                    src={crackHero}
                    title='Crack Social'
                    roles={roles}
                    description=
                    'In the midst of working 60+ hours a week at my agency in LA, I found myself wondering if there was more to life than creating content with a shelf life of mere hours. I had been playing around with an idea that would flip the concept of social media on its head since 2009, and I knew that if I didn’t start running after it, I never would. So I started putting in the hours on the side to make it happen. After almost a year of grinding, and very little sleep, I was able to build a product and brand that attracted some angel investors. Since leaving my job and going full-time, I’ve been fighting tooth and nail to learn everything I possibly can to make this a reality. I’ve learned many priceless lessons about business, design, development, marketing, and my own character in the process.'
                />

    <Fade direction='top' cascade='true'>
        <section className='fifty-fifty'>
                <div className='full-prototype-info'>
                        <div>
                            <h5 className='section-role'>Concept</h5>
                            <h3 className='section-title'>What Is Crack?</h3>
                        </div>
                    
                        <p className='section-description'>
                        See what’s going on around you, find fun stuff to do with your friends, and join local groups centered around you interests.
                        </p>
                </div>
                <div className='prototype-wrapper'>
                        <a className='prototype-link' target='_blank' href='https://xd.adobe.com/view/9bc5cc0e-ab04-4f53-69d8-80790755748f-edf4/?fullscreen'>
                            <img src={require('../images/crack/prototype-screen.png')} alt=""/>
                            <h5>Checkout the full prototype!</h5>
                        </a>
                        {/* <iframe  src="https://xd.adobe.com/embed/9bc5cc0e-ab04-4f53-69d8-80790755748f-edf4/?fullscreen" sandbox frameborder="0" ></iframe> */}
                </div>
        </section>
        
        <section className='fifty-fifty'>
                <div className='paper-info'>
                        <div>
                            <h5 className='section-role'>Paper Prototype</h5>
                            <h3 className='section-title'>And Awaaaay We Go</h3>
                        </div>
                        <p className='section-description'>
                            I made blockframes for the seven main screens in Illustrator, printed them out in iPhone mockups, and taped all the screens together to make an “interactive” paper prototype.
                        </p>
                </div>
                <div className='paper-image'>
                        <img src={paperPrototype} alt=""/>
                </div>
        </section>

            <section>
                <h5 className='section-role'>UX Discovery</h5>
                <div className='section-info'>
                    <h3 className='section-title'>First Things... Second</h3>
                    <p className='section-description'>
                        The paper prototype taught me that we first needed to define what we were building, not how we were building it. I set out to get in the heads of our users by mapping out the user journey. I also conducted some user interviews to see what was most important to our users.
                    </p>
                </div>
                <div className='first-things'>
                    <div>
                        <img src={paperNotes1} alt="the first user flow I wrote down on paper"/>
                    </div>
                    <div>
                        <img src={paperNotes2} alt="my notes breaking down the content a user would see on the Map screen"/>
                    </div>
                    <div>
                        <img src={paperNotes3} alt="my notes breaking down the content a user would see on the Discover screen"/>
                    </div>
                </div>
            </section>
        
            <section>
                <h5 className='section-role'>Hi Fidelity Prototyping</h5>
                <div className='section-info'>
                    <h3 className='section-title'>Sharpest Tool in the Shed</h3>
                    <p className='section-description'>
                    I chose to use Proto.io to create our high fidelity mockup. The screen design capabilities were on par with its competitors, but its prototyping features were much more robust. I mocked up all the screens, and then connected all of them in a working prototype. Proto.io even made it possible for me to animate micro interactions.
                    </p>
                </div>
            </section>
            <div className='full-width-image'>
                <img src={protoIo} alt="layout of seven screens on iPhone devices"/>
            </div>

        <section>
                <h5 className='section-role'>User Testing</h5>
                <div className='section-info'>
                    <h3 className='section-title'>More to Learn</h3>
                    <p>
                        I ran 50+ user tests on this prototype over the course of four months. A lot of them were in person, but we also did 10 tests using Validately. I still prefer to do tests in person, using a think aloud approach, as I can get insights into the “why” instead of just the “what” by asking open-ended questions to better understand the thinking that went into their decision process. Used together, surveys, usability testing, and interviews were very effective in coming to an understanding of what our users wanted
                    </p>
                </div>
                <h4 className='callout'>Things I learned</h4>
                    <div className='learnings'>
                        <div className='learning'>
                            <h3>01</h3>
                            <p>
                                We were trying to do too much and needed to stick with the familiar.
                            </p>
                        </div>
                        <div className='learning'>
                            <h3>02</h3>
                            <p>Nobody knew what the four buttons on the cards were for or what the icons meant.</p>
                        </div>
                        <div className='learning'>
                            <h3>03</h3>
                            <p>Nobody understood why their profile picture was showing up on the bottom of the cards.</p>
                        </div>
                        <div className='learning'>
                            <h3>04</h3>
                            <p>The Cliques screen was causing some confusion. What are cliques? What was the clique feed and how was it different from the feed in the main nav? </p>
                        </div>
                        <div className='learning'>
                            <h3>05</h3>
                            <p>People really liked the layout of the profile, but they didn't understand, once again, what all the icons and numbers around the circle meant.</p>
                        </div>
                        <div className='learning'>
                            <h3>06</h3>
                            <p>Clicking the logo in the header opened up the calendar screen, but nobody knew that you could click the logo.</p>
                        </div>
                    </div>
            </section>

            <section>
                <div className='section-info'>
                    <div>
                        <h5 className='section-role'>Branding Design</h5>
                        <h3 className='section-title'>Brew to Crack</h3>
                    </div>
                    <div>
                        <p>
                            We started out as Brew, but we discovered that there was a trademark issue, and we would have to rename the company. We wanted to keep the name simple, and preferably be able to use it as a verb like “Uber there” or “Google it.” Crack was one of the first names we brought up, and we kept coming back to it, so we went for it.
                        </p>
                        <p>
                            I wanted the logo to be minimal and geometric, and was interested in the idea of six degrees of separation. My initial drafts were too complex, and everybody we shared the idea with said the same thing: why don’t you just make it a C? One night while watching a Netflix documentary about math, the idea hit me, and our logo was born.
                        </p>
                    </div>
                </div>
            </section>
            <div className='full-width-image'>
                <img src={crackBranding} alt="crack logo mark and logo type in white"/>
            </div>

        <section>
                <h5 className='section-role'>Video Production</h5>
                <div className='section-info'>
                    <h3 className='section-title'>Slangin' Crack</h3>
                    <p>
                        Having a high-fi prototype made it a lot easier to get the idea across, but there was still a disconnect with potential investors. Clicking through a prototype with static content was nice, but it didn’t help people outside of our target market understand how it would fit into people’s lives. We decided to make a promo video to show the app instead of tell people about it. We recruited a few friends to help us shoot a promo over 4th of July weekend.
                    </p>
                </div>
                <div className='iframe-wrapper promo-video'>
                    <iframe src="https://player.vimeo.com/video/182815059" title='Crack Promo Video' frameborder="0" allow="fullscreen" allowfullscreen></iframe>
                </div>
        </section>

        <section>
                <h5 className='section-role'>Product Research</h5>
                <div className='section-info'>
                    <h3 className='section-title'>Ducks in a Row</h3>
                    <p>
                        With money to hire a developer and make this idea a reality, I wanted to make sure that we were building the right product before handing it off. Some things had changed in the  tech landscape, and I had some new ideas that I needed to validate first. I knew we would need some mixed methods research to fully understand our user and their needs. I started with personas, user flows, and stories before sending out a survey. I also did a lot of qualitative research by conducting interviews.  Equipped with the insights from my research, I set out to create our information architecture, and I actually put together our first API schema.
                    </p>
                </div>
            <div className='sub-section'>
                    <div className='section-subhead'>
                        <h5 className='section-role'>Personas</h5>
                        <p>
                            Jay and Nikki were the personas we came up with after talking to a lot of people about their lives and what they wanted out of social media
                        </p>
                    </div>
                    <div className='double-wide'>
                        <img src={personaJay} alt="a user persona for a man named Jay"/>
                        <img src={personaNikki} alt="a user persona for a woman named Nikki"/>
                    </div>
            </div>
            <div className='sub-section'>
                    <div className='section-subhead'>
                        <h5 className='section-role'>User Stories & Flows</h5>
                        <p>
                            Writing it out is the easiest way for me to set an idea or a flow in motion 
                        </p>
                    </div>
                    <div className='double-wide'>
                        <img src={paperFlow} alt="a handwritten user story that follows the journey of a girl named Camila"/>
                        <img src={paperFlow2} alt="a handwritten set of notes for the user experience of the map screen"/>
                        <img src={paperFlow3} alt="a handwritten set of notes for the user experience of the discover screen"/>
                        <img src={paperFlow4} alt="a handwritten set of notes for the user experience of messaging and communication between users and groups"/>
                    </div>
            </div>
            <div className='sub-section'>
                    <div className='section-subhead'>
                        <h5 className='section-role'>Information Architecture</h5>
                        <p>
                            I put together all the touchpoints, where that was taking users, and what they were seeing
                        </p>
                    </div>
                    <div>
                        <img src={paperFlow5} alt="a handrawn chart showing the organization and flow of information in the app"/>
                    </div>
            </div>
        </section>

        <div className='start-from-scratch'>
                <h5 className='section-role'>Product Design</h5>
                <div className='section-info'>
                        <h3 className='section-title'>Start From Scratch</h3>
                        <p>
                            New user flows and information architecture meant a redesign was in order. I started with paper sketches, made blockframes, created a design system, and finally put it all together with an interactive InVision prototype. I switched my workflow from Proto.io to Sketch + InVision, which allowed me to hand over the designs to our developer complete with specs through InVision.
                        </p>
                </div>
            <div className='sub-section'>
                    <div className='section-subhead'>
                        <h5 className='section-role'>Paper Sketches</h5>
                        <p>
                            After nailing down the information architecture, I moved on to paper sketches
                        </p>
                    </div>
                    <div className='double-wide'>
                        <img src={paperSketch1} alt="handrawn sketches of app interface inside a printed out iPhone frame"/>
                        <img src={paperSketch2} alt="handrawn sketches of app interface inside a printed out iPhone frame"/>
                    </div>
            </div>
            <div className='sub-section'>
                    <div className='section-subhead'>
                        <h5 className='section-role'>Blockframes</h5>
                        <p>
                            I use blockframes to understand what the component situation looks like, and from there break down the atomic structure of the product
                        </p>
                    </div>
                    <div>
                        <img src={blockframes} alt="a series of user interface blockframes set in iPhone frames"/>
                    </div>
            </div>
            <div className='sub-section'>
                    <div className='section-subhead'>
                        <h5 className='section-role'>Design System</h5>
                        <p>
                            I had been a big fan of Atomic Design since I read it, so I was excited to make a design system
                        </p>
                    </div>
                    <div>
                        <img src={designSystem} alt="some of the sections of the design system i created"/>
                    </div>
            </div>
            <div className='sub-section'>
                    <div className='section-subhead'>
                        <h5 className='section-role'>High-fi Mockups & Interactive Prototype</h5>
                        <p>
                            This is the moment all the research and preparation leads up to. It’s exhilarating to see your work take on it’s official, ideal form
                        </p>
                    </div>
                    <div>
                        <img src={highFi2017} alt="high fidelity mockups of some of the various screens of the app"/>
                    </div>
            </div>
        </div>
        
        <section>
                <h5 className='section-role'>Product Management</h5>
                <div className='section-info'>
                    <h3 className='section-title'>I'm the Manager Now</h3>
                    <p>
                        Now that we had a developer, I was adding product management to my list of responsibilities. It was my first time working with a programmer this closely, and while there were some unique challenges that cropped up, the process felt natural to me. I worked directly with our programmer every day for nine months to get the app built. I would setup our weekly sprints in Jira, and then prepare a Google Slides document with redlines showing the fixes that needed to be made. 
                    </p>
                </div>
                <div className='double-wide'>
                    <div className='vertical-gallery-block'>
                        <img src={require('../images/crack/task-list-screenshot.png')} alt=""/>
                    </div>
                    <div className='vertical-gallery-block'>
                        <img src={require('../images/crack/jira-backlog-screenshot.png')} alt=""/>
                    </div>
                </div>
        </section>

        <section className='col-2-1'>
            <div>
                    <div>
                        <h5 className='section-role'>Beta Testing</h5>
                        <h3 className='section-title'>Beta FTW</h3>
                    </div>
                    <div>
                        <p>
                            Once the app was stable enough, we started to do some beta testing using TestFlight. We started small, with about 10 friends using and testing the app. After a little over a month, we started inviting more friends to join our closed beta. We had 60+ people using the app in four different cities across the US. We learned a lot from the users as we shared our weekly builds with them. A lot of the feedback was about small bugs that we already knew about, but every once in a while, we’d get a nugget of feedback that made a real difference. 
                        </p>
                    </div>
            </div>
                <div className='iphone8-iframe-wrapper'>
                    <iframe src="https://player.vimeo.com/video/378346045" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
        </section>

        <section>
                <h5 className='section-role'>Marketing Creative & Management</h5>
                <div className='section-info section-info-half'>
                    <h3 className='section-title'>Where'd I Put That Marketing Hat?</h3>
                    <p>
                        As we entered our last few sprints before the App Store, I started working on a marketing campaign for our launch. We decided Instagram Stories would provide the best way to get in front of our target market. I came up with a format that would work regardless of the footage, and would enable us to create multiple ads with minimal effort. Over the course of the next couple weeks, with the help of a few of our beautiful friends, we shot a series of ads – all for the price of an iPhone stabilizer and paddle board rental.
                    </p>
                </div>
                <div className='three-video-grid'>
                    <div className='iphone8-iframe-wrapper'>
                        <iframe src="https://player.vimeo.com/video/277024874" frameborder="0" allow="fullscreen" allowfullscreen></iframe>
                    </div>
                    <div className='iphone8-iframe-wrapper'>
                        <iframe src="https://player.vimeo.com/video/309378858" frameborder="0" allow=" fullscreen" allowfullscreen></iframe>
                    </div>
                    <div className='iphone8-iframe-wrapper'>
                        <iframe src="https://player.vimeo.com/video/340383936" frameborder="0" allow=" fullscreen" allowfullscreen></iframe>
                    </div>
                    <div className='iphone8-iframe-wrapper'>
                        <iframe src="https://player.vimeo.com/video/340384047" frameborder="0" allow=" fullscreen" allowfullscreen></iframe>
                    </div>
                    <div className='iphone8-iframe-wrapper'>
                        <iframe src="https://player.vimeo.com/video/314364773" frameborder="0" allow=" fullscreen" allowfullscreen></iframe>
                    </div>
                    <div className='iphone8-iframe-wrapper'>
                        <iframe src="https://player.vimeo.com/video/313611793" frameborder="0" allow=" fullscreen" allowfullscreen></iframe>
                    </div>
                </div>
        </section>

        <section>
            <div>
                <div>
                        <h5 className='section-role'>Coding Bootcamp</h5>
                        <h3 className='section-title'>Back to School, Back to School</h3>
                </div>
                <div>
                        <p>
                            At this point, I fully realized how necessary it was for me to understand front and backend programming, at least at a basic level. I had been thinking about doing a coding bootcamp since before we started Crack, but it was quickly becoming apparent that it was going to be necessary. So, what better time to dive head first into a full-time bootcamp than when we’re launching an app in the App Store, complete with a marketing campaign?
                        </p>
                        <p>
                            It was an arduous three months, but I put everything I had into that bootcamp. From 9:00–5:00 (at least), I was pounding my fingers on the keyboard – and sometimes my head against the wall – writing code and trying to make sense of what I was typing. Then from 6:00 to 12:00 (at least), I was back into managing the product and our ad campaign. It was a crazy time, but I powered with a determination to learn everything I could. I came this close to burning out, but I was kept alive by the feeling that I was getting closer to reaching my goals of becoming the product master I knew I could be.
                        </p>
                        <p>
                            After I finished the bootcamp, I finally felt comfortable cracking open our codebase and inspecting some things. As I expected, it was far too expansive for me to jump in and start writing code that would contribute; however, after doing some digging, I did find a handful of things that ended up saving us.
                        </p>
                        <p>
                            First, I realized that our image compression was terrible. The package we were using to process images was actually making the file sizes bigger. That was causing huge problems for our load times, and was crashing phones that were low on memory and/or storage. We also weren’t optimizing images, so even the smallest thumbnails were using the full size image file. Yikes. After many back-and-forths with our programmer about how big the problem was and how to handle it, I coded a quick mockup of what we needed. I had dabbled with Cloudinary in the bootcamp, so I knew that with the amount of images we were handling, we’d be better off using a CDN like that. Implementing Cloudinary cut our server loads by over 90%, and improved our page load times by seconds – yes, multiple seconds.
                        </p>
                        <p>
                            From that day forward I have worked in a similar way to continually improve core pieces of our app, such as: upgrading and streamlining our navigation framework, improving our use of the Mapbox API, debugging and correcting our Google Places API implementation, and a handful of other things along the way that have drastically improved the overall performance of the app.         
                        </p>
                </div>
            </div>
        </section>

        <section>
                <h5 className='section-role'>App Store Launch</h5>
                <div className='section-info'>
                    <h3 className='section-title'>App Store at Long Last</h3>
                    <p>
                        If you’re not embarrassed by the product you launch, then you launched too late, right? Well, after a few months of testing, and countless revisions, we were finally ready to launch in the App Store. This is the moment we had been working for since day one, and it came with an overwhelming feeling of victory.
                    </p>
                </div>
                <div className='four-image-grid'>
                    <div className='vertical-gallery-block'>
                        <img src={appStore1} alt="the map screen with the caption 'who. what. when. where'"/>
                    </div>
                    <div className='vertical-gallery-block'>
                        <img src={appStore2} alt="the create screen with the caption 'small activities or huge events'"/>
                    </div>
                    <div className='vertical-gallery-block'>
                        <img src={appStore3} alt="the groups screen with the caption 'your friends and groups together'"/>
                    </div>
                    <div className='vertical-gallery-block'>
                        <img src={appStore4} alt="the discover screen with the caption 'see what's crackin - anytime, anywhere'"/>
                    </div>
                </div>
        </section>
        
        <section>
            <div className='fifty-fifty the-boys'>
                <div>
                        <h5 className='section-role'>Product & Business Management</h5>
                        <h3 className='section-title'>Another Round for the Boys</h3>
                        <p>
                            After some learning and optimizing our marketing campaign, we gained over 400 new users in two weeks for less than $1000. This new user data, along with the strides we had made with our product, was enough for us to find a new angel investor.                    
                        </p>
                </div>
                <div className='roadmap-wrapper'>
                        <img src={roadmap} alt="redacted version of a roadmap i created"/>
                </div>
            </div>
    
        </section>
        <div className='sub-section'>
                <div className='section-subhead'>
                    <h5 className='section-role'>Pitch Deck</h5>
                    <p>
                        A lot had happened since our last raise, and we needed a pitch deck that told the whole story of our evolution, and where our roadmap would take us                       
                    </p>
                </div>
        </div>
            <div className='full-width-image'>
                <img src={pitchDeck} alt="a collection of slides from a pitch deck i wrote and designed"/>
            </div>

        <section>
            <div>
                    <h5 className='section-role'>Marketing Creative & Management</h5>
                    <h3 className='section-title'>Not to be Confused With...</h3>
                    <p>
                        One of our friends had been on The Bachelorette, and a new season was coming out soon. One day we were talking about how to work together to capitalize on that, and I had an idea. We could make a whole series from this double entendre, but we decided to make two first. This campaign was not successful, but I still learned some things.                
                    </p>
            </div>
                <div className='two-video-grid'>
                    <div className='iphone8-iframe-wrapper'>
                        <iframe src="https://player.vimeo.com/video/309799602" frameborder="0" allow=" fullscreen" allowfullscreen></iframe>
                    </div>
                    <div className='iphone8-iframe-wrapper'>
                        <iframe src="https://player.vimeo.com/video/393843091" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                    </div>
                </div>
        </section>
        
        <section>
            <div className='section-centered'>
                    <h5 className='section-role'>Product Design & Management</h5>
                    <h3 className='section-title'>All Hail v1.0</h3>
                    <p>
                        With that campaign behind us, I wanted to dive deep into the product again and get it ready for a production release. This required some ruthless prioritization, scraping away all pretense with our users and getting to the core of their true desires, and putting that together into something that worked flawlessly. I had to remove features that were holding us back, tweak existing features to make us stand out, and handle some user pain points the way they wanted us to. Three months of grinding gave way to our first production release – v1.0.         
                    </p>
            </div>
        </section>
            <div className='full-width-image last-image'>
                <img src={highFi2019} alt="some of the screens from our first production version"/>
            </div>

    </Fade>
        
        

        <ProjectNav
            next='/true-organic'
            nextTitle='True Organic'
        />
    </div>
)