export const farmPhotos = [
    {
      src: require('../images/cal-organic/cal-organic-farm-1.jpg'),
      width: 3,
      height: 2
    },
    {
      src: require('../images/cal-organic/cal-organic-farm-2.jpg'),
      width: 3,
      height: 2
    },
    {
      src: require('../images/cal-organic/cal-organic-farm-3.jpg'),
      width: 3,
      height: 2
    },
    {
      src: require('../images/cal-organic/cal-organic-farm-4.jpg'),
      width: 3,
      height: 2
    },
    {
      src: require('../images/cal-organic/cal-organic-farm-5.jpg'),
      width: 2,
      height: 3
    },
    {
      src: require('../images/cal-organic/cal-organic-farm-6.jpg'),
      width: 3,
      height: 2
    },
    {
      src: require('../images/cal-organic/cal-organic-farm-7.jpg'),
      width: 3,
      height: 2
    },
    {
      src: require('../images/cal-organic/cal-organic-farm-8.jpg'),
      width: 2,
      height: 3
    },
    {
      src: require('../images/cal-organic/cal-organic-farm-9.jpg'),
      width: 3,
      height: 2
    },
    {
      src: require('../images/cal-organic/cal-organic-farm-10.jpg'),
      width: 2,
      height: 3
    },
    {
      src: require('../images/cal-organic/cal-organic-farm-11.jpg'),
      width: 3,
      height: 2
    },
    {
      src: require('../images/cal-organic/cal-organic-farm-12.jpg'),
      width: 2,
      height: 3
    },
    {
      src: require('../images/cal-organic/cal-organic-farm-13.jpg'),
      width: 2,
      height: 3
    },
    {
      src: require('../images/cal-organic/cal-organic-farm-14.jpg'),
      width: 3,
      height: 2
    },
    {
      src: require('../images/cal-organic/cal-organic-farm-15.jpg'),
      width: 3,
      height: 2
    },
    {
      src: require('../images/cal-organic/cal-organic-farm-16.jpg'),
      width: 3,
      height: 2
    },
    {
      src: require('../images/cal-organic/cal-organic-farm-17.jpg'),
      width: 3,
      height: 2
    },
    {
      src: require('../images/cal-organic/cal-organic-farm-18.jpg'),
      width: 3,
      height: 2
    },
    {
      src: require('../images/cal-organic/cal-organic-farm-19.jpg'),
      width: 3,
      height: 2
    },
    {
      src: require('../images/cal-organic/cal-organic-farm-20.jpg'),
      width: 3,
      height: 2
    },
    {
      src: require('../images/cal-organic/cal-organic-farm-21.jpg'),
      width: 3,
      height: 2
    },
    {
      src: require('../images/cal-organic/cal-organic-farm-22.jpg'),
      width: 3,
      height: 2
    },
    {
      src: require('../images/cal-organic/cal-organic-farm-23.jpg'),
      width: 3,
      height: 2
    },
    {
      src: require('../images/cal-organic/cal-organic-farm-24.jpg'),
      width: 2,
      height: 3
    }
    
  ];
  