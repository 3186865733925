import React from 'react'
import { Fade } from 'react-awesome-reveal'

// COMPONENTS AND STUFF
import Header from '../components/Header'

export default () => (
    <div className='page-wrapper'>
        <Header />
        <div className='about-page'>
            <Fade direction='top' triggerOnce='true' cascade='false'>
                <div className='derek-photo'>
                    <img src={require('../images/derek-bodily.jpg')} alt=""/>
                </div>
                <h1>Derek Bodily</h1>
                <div className='about-section about-who'>
                    <h2>Who I Am</h2>
                    <p>
                        I’m an all-purpose creative. Apps, ads, cameras, code, brands – you name it, I’ve worked on it. I relish in the challenges of telling stories and creating things that are equally useful and beautiful. 
                    </p>
                </div>
                <div className='about-section about-what'>
                    <h2>What I Do</h2>
                    <p>
                        I create and manage products and marketing. From concept to deployment. From ideation to analytics. I’ve amassed a skill set that encompasses what is usually the work of a few teams. I feel equally comfortable in the trenches as an individual contributor as I do leading with influence and emotional intelligence.  
                    </p>
                </div>
                <div className='about-section about-want'>
                    <h2>What I Want Do</h2>
                    <p>
                        My number one desire is to work with cross-functional teams to create innovative products, with a focus on finding solutions to the struggles inherent in the design / development relationship. I operationalize design (design-ops) with my unique perspective as someone who understands both sides of the product coin – with the added bonus of a background in marketing. 
                    </p>
                    <p>
                        Desire number two involves using as many of my skills as possible. This could mean actually creating the work myself, but I also love being on a team and helping make sense of the big picture vision while directing the creative solutions we come up with on the daily.            
                    </p>
                </div>
                <h2>Tools I Use</h2>
                <div className='about-section tools'>
                    <div className='tool-list'>
                        <h4>Product Design</h4>
                        <ul>
                            <li>Sketch</li>
                            <li>XD</li>
                            <li>Figma</li>
                            <li>Framer</li>
                            <li>InVision</li>
                            <li>Abstract</li>
                            <li>Flinto</li>
                            <li>Proto.io</li>
                            <li>Google Forms</li>
                        </ul>
                    </div>
                    <div className='tool-list'>
                        <h4>Product Management</h4>
                        <ul>
                            <li>Jira</li>
                            <li>Zoom</li>
                            <li>Asana</li>
                            <li>Optimizely</li>
                            <li>Trello</li>
                            <li>UserZoom</li>
                            <li>Google Drive</li>
                            <li>Airtable</li>
                            <li>Google Analytics</li>
                        </ul>
                    </div>
                    <div className='tool-list'>
                        <h4>Tech</h4>
                        <ul>
                            <li>React</li>
                            <li>React Native</li>
                            <li>HTML/CSS</li>
                            <li>Firebase</li>
                            <li>VSCode</li>
                            <li>Node</li>
                            <li>Heroku</li>
                            <li>GitHub</li>
                            <li>App Center</li>
                        </ul>
                    </div>
                    <div className='tool-list'>
                        <h4>Marketing</h4>
                        <ul>
                            <li>Photoshop</li>
                            <li>Illustrator</li>
                            <li>Premiere Pro</li>
                            <li>After Effects</li>
                            <li>Facebook Ads Manager</li>
                            <li>Google Analytics</li>
                        </ul>
                    </div>
                </div>
                <div className='about-section skills'>
                    <h2>Skills As Deliverables</h2>
                    <div className='skill-list'>
                        <ul>
                            <li>UX Design</li>
                            <li>UX Research</li>
                            <li>Prototyping</li>
                            <li>Documentation</li>
                            <li>Product Requirements</li>
                            <li>Marketing Strategy</li>
                            <li>Marketing Creative</li>
                            <li>Marketing Management</li>
                            <li>Video Production</li>
                            <li>Photography</li>
                            <li>Motion Graphics</li>
                            <li>Etc.</li>
                        </ul>
                    </div>
                </div>
            </Fade>
        </div>
    </div>
)