import React from 'react'
import { Fade } from 'react-awesome-reveal'

import ProjectIntro from '../components/ProjectIntro'
import Header from '../components/Header'
import kfcSocial from '../components/KfcSocial'
import ProjectNav from '../components/ProjectNav'

// IMAGE IMPORTS
import kfcHero from '../images/kfc/kfc-hero.jpg'
import chickenfy1 from '../images/kfc/chickenfy1.jpg'
import chickenfy2 from '../images/kfc/chickenfy2.jpg'
import oscars from '../images/kfc/kfc-oscars.jpg'
import may4th from '../images/kfc/may-4th.jpg'


let socialContent = kfcSocial.map(image => 
    <div className='vertical-gallery-block'>
        <img src={image} />
    </div>
)
        

export default () => (
    <div className='page-wrapper'>
        <Header />
        <ProjectIntro
            src={kfcHero}
            title='KFC'
            description='I was pretty excited when I got the news that KFC would be my first client at my first big boy job. Working on one of the most recognized brands in America was a super cool way to get my feet wet. I worked on their #HowDoYouKFC campaign. It was a time when the 60-year old brand was starting to experiment with digital advertising, and we got to do some fun stuff.'
        />

    <section>
        <div className='section-info'>
            <Fade direction='left' cascade='true' triggerOnce='true'>
                <h3 className='section-title'>Digital Videos</h3>
            </Fade>
            <Fade direction='right' cascade='true' triggerOnce='true'>
                <p className='section-description'>
                    The #HowDoYouKFC campaign was heavily focused on TV commercials because that's what KFC was used to, but our agency was trying to get them to break into digital content creation so they could get the attention of the Millennial market. My creative director knew my background, and he gave me the opportunity to concept as many crazy and fun videos as I could. I came up with a list of over 100 videos, and got to pitch them directly to the agency's CCO. The videos focused mostly on the Go Cup, and helped turn around a years-long sales slump.
                </p>
            </Fade>
        </div>
        <div className='iframe-gallery'>
            <div className='iframe-wrapper kfc-hero-video'>
                <iframe src="https://player.vimeo.com/video/97148322" title='KFC Cannes Entry' frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            </div>
            <div className='iframe-wrapper'>
                <iframe src="https://player.vimeo.com/video/106942960" title='KFC Motocross Backflip' frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            </div>
            <div className='iframe-wrapper'>
                <iframe src="https://www.youtube.com/embed/p6QB69KvzK0" title='Irish Dancing Go Cup' frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className='iframe-wrapper'>
                <iframe src="https://player.vimeo.com/video/106942962" title='KFC Go Cup Arm Wrestling' frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            </div>
            <div className='iframe-wrapper'>
            <iframe src="https://player.vimeo.com/video/138584494" title='KFC Trike Drifting' frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            </div>
            <div className='iframe-wrapper'>
            <iframe src="https://player.vimeo.com/video/377862205" title='KFC Double Down Tattoo' frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            </div>
            <div className='iframe-wrapper'>
                <iframe src="https://player.vimeo.com/video/289572874" title='KFC Corsage' frameborder="0" allow="fullscreen" allowfullscreen></iframe>
            </div>
        </div>
    </section>

    <section>
        <div className='section-info'>
            <Fade direction='left' cascade='true' triggerOnce='true'>
                <h3 className='section-title'>Social Content</h3>
            </Fade>
            <Fade direction='right' cascade='true' triggerOnce='true'>
                <p className='section-description'>
                When I started full-time, the social content creation was transferred to me from one of the account planners. I took the opportunity to completely change their feeds. We transitioned from corny graphics to simple and authentic lifestyle photography. The style fit the #HowDoYouKFC campaign, and resonated with our audiences. In the 18 months I worked on KFC, their total social following more than doubled, and engagement was consistently high across all channels — Facebook, Instagram, YouTube, and Twitter. Brand sentiment on social was 4x higher than the TV commercials, even with 1/100 of the budget.
                </p>
            </Fade>
        </div>
        <div className='vertical-gallery'>
            <Fade direction='top' cascade='true' triggerOnce='true'>
                {socialContent}
            </Fade>
        </div>
    </section>

    <section>
        <div className='section-info'>
            <Fade direction='left' cascade='true' triggerOnce='true'>
                <h3 className='section-title'>Chickenfy</h3>
            </Fade>
            <Fade direction='right' cascade='true' triggerOnce='true'>
                <p className='section-description'>
                KFC had struck a few timely viral chords with our social team guiding them, so they were excited to jump on fun internet trends. I looked at a BuzzFeed list of upcoming “holidays” and saw National Fried Chicken Day coming up in three weeks. We raced to get the concept approved, and started building the site with an agency vendor a few days later. It took two weeks to build the site, but it worked perfectly. The copy we wrote and the images we provided were enough to completely chickenfy any website from any URL.
                <br />
                <br />
                Then KFC killed it the day before National Fried Chicken day. C’est la vie.
                </p>
            </Fade>
        </div>
        <div className='vertical-gallery'>
            <Fade direction='top' cascade='true' triggerOnce='true'>
                <div className='vertical-gallery-block'>
                    <img src={chickenfy1} alt=""/>
                </div>
                <div className='vertical-gallery-block'>
                    <img src={chickenfy2} alt=""/>
                </div>
            </Fade>
        </div>
        
    </section>

    <section>
        <div className='section-info'>
            <Fade direction='left' cascade='true' triggerOnce='true'>
                <h3 className='section-title'>Oscars</h3>
            </Fade>
            <Fade direction='right' cascade='true' triggerOnce='true'>
                <p className='section-description'>
                Our community managers live-tweeted The Oscars and gave out the KFC Bucket Awards for our own KFC themed categories.
                </p>
            </Fade>
        </div>
        <Fade direction='top' cascade='true' triggerOnce='true'>
            <div className="single-image-wrapper">
                <img src={oscars} alt=""/>
            </div>
        </Fade>
    </section>

    <section>
        <div className='section-info'>
        <Fade direction='left' cascade='true' triggerOnce='true'>
            <h3 className='section-title'>May the 4th</h3>
        </Fade>
        <Fade direction='right' cascade='true' triggerOnce='true'>
            <p className='section-description'>
            Anyways, you get it now. We tried to get in on every bit of the action that we could. It was the wild west back then, and every brand was just figuring it out by taking every shot they could.
            </p>
        </Fade>
        </div>
        <Fade direction='top' cascade='true' triggerOnce='true'>
            <div className='single-image-wrapper'>
                <img src={may4th} alt=""/>
            </div>
        </Fade>
    </section>

    <ProjectNav
        prev='/true-organic'
        prevTitle='True Organic'
        next='/cal-organic'
        nextTitle='Cal Organic'
    />

    </div>
)