import React from 'react'
import { Link } from 'react-router-dom'
import { Fade } from 'react-awesome-reveal';

import '../App.css'

export default (props) => (
    <Link to={props.link}>
        <Fade direction='top' triggerOnce='true'>
            <div className='menu-item' id={props.id}>
                <div className='menu-image'>
                    <img  src={props.src} alt=""/>
                </div>
                
                <h1 className='menu-title'>{props.title}</h1>
            </div>
        </Fade>
    </Link>
)