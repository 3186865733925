import React from 'react'
import { Link } from 'react-router-dom'


export default class Header extends React.Component {
    componentDidMount() {
      window.addEventListener("scroll", this.resizeHeaderOnScroll);
    }
    resizeHeaderOnScroll() {
      const distanceY = window.pageYOffset || document.documentElement.scrollTop,
        shrinkOn = 200,
        headerEl = document.getElementById("header");
  
      if (distanceY > shrinkOn) {
        headerEl.classList.add("smaller");
      } else {
        headerEl.classList.remove("smaller");
      }
    }
    render() {
      return (
        <header id='header'>
            <Link className='logo' to='/'>
                <img  src={require("../images/derek-bodily-logo-3d.png")} alt=""/>
            </Link>
            <Link className='header-link work-link' to='/work'>Work</Link>
            <Link className='header-link about-link' to='/about'>About</Link>
        </header>
      )
    }
}










// export default () => (
//     <header>
//         <Link className='logo' to='/'>
//             <img  src={require("../images/derek-bodily-logo-3d.png")} alt=""/>
//         </Link>
//         <Link className='header-link work-link' to='/work'>Work</Link>
//         <Link className='header-link about-link' to='/about'>About</Link>
//     </header>
// )