import React from 'react'

import ProjectIntro from '../components/ProjectIntro'
import Header from '../components/Header'
import ProjectNav from '../components/ProjectNav'

import codeHero from '../images/code/code-hero.jpg'

export default () => (
    <div className='page-wrapper'>
        <Header />
        <ProjectIntro
            src={codeHero}
            title='Code'
            description='Migas kinfolk unicorn taiyaki flannel, poutine narwhal VHS poke ennui health goth 8-bit intelligentsia asymmetrical. Migas master cleanse tilde roof party, kickstarter palo santo asymmetrical unicorn before they sold out lomo direct trade ethical. Af fixie gochujang, mlkshk adaptogen tacos live-edge pug skateboard lyft pok pok cred vaporware man bun. Distillery polaroid bicycle rights twee affogato you probably haven&quott heard of them. Chia aesthetic chartreuse distillery polaroid, portland crucifix VHS cold-pressed artisan venmo palo santo copper mug.'
        />

        <ProjectNav
            prev='/mattel'
            prevTitle='Mattel'
            next='/video'
            nextTitle='Video'
        />
        
    </div>
)