import React from 'react'
import { Fade } from 'react-awesome-reveal'
 
import ProjectIntro from '../components/ProjectIntro'
import Header from '../components/Header'
import ProjectNav from '../components/ProjectNav'

import mattelHero from '../images/mattel/mattel-hero.jpg'

// UNO IMAGES
import uno1 from '../images/mattel/uno-1.jpg'
import uno2 from '../images/mattel/uno-2.jpg'
import uno3 from '../images/mattel/uno-3.jpg'
import uno4 from '../images/mattel/uno-4.jpg'
import uno5 from '../images/mattel/uno-5.jpg'
import uno6 from '../images/mattel/uno-6.jpg'
import uno7 from '../images/mattel/uno-7.jpg'
import uno8 from '../images/mattel/uno-8.jpg'
import uno9 from '../images/mattel/uno-9.jpg'
import uno10 from '../images/mattel/uno-10.jpg'
import uno11 from '../images/mattel/uno-11.jpg'
import uno12 from '../images/mattel/uno-12.jpg'
import uno13 from '../images/mattel/uno-13.jpg'
import uno14 from '../images/mattel/uno-14.png'
import uno15 from '../images/mattel/uno-15.jpg'
import uno16 from '../images/mattel/uno-16.jpg'
import uno17 from '../images/mattel/uno-17.png'
import uno18 from '../images/mattel/uno-18.jpg'

import apples1 from '../images/mattel/apples-1.jpg'
import apples2 from '../images/mattel/apples-2.png'
import apples3 from '../images/mattel/apples-3.jpg'
import apples4 from '../images/mattel/apples-4.jpg'
import apples5 from '../images/mattel/apples-5.jpg'
import apples6 from '../images/mattel/apples-6.png'

import scrabble1 from '../images/mattel/scrabble-1.png'
import scrabble2 from '../images/mattel/scrabble-2.png'

let unoImages = [
    uno1,
    uno2,
    uno3,
    uno4,
    uno5,
    uno6,
    uno7,
    uno8,
    uno9,
    uno10,
    uno11,
    uno12,
    uno13,
    uno14,
    uno15,
    uno16,
    uno17,
    uno18
]
const unoGallery = unoImages.map(image =>
    <div className='vertical-gallery-block'>
        <img src={image} alt=""/>
    </div>
)

let mattelImages = [
    apples1,
    apples2,
    apples3,
    apples4,
    apples5,
    apples6
]
const mattelGallery = mattelImages.map(image =>
        <div className='vertical-gallery-block'>
            <img src={image} alt=""/>
        </div>
    )



export default () => (
    <div className='page-wrapper'>
        <Header />
        <ProjectIntro
            src={mattelHero}
            title='Mattel'
            description='I created content for several of the Mattel game brands – Uno, Apples to Apples, Scrabble, and Pictionary. Each brand had its own voice and content pillars. It was fun working on such classic brands that embody the idea of fun for a lot of American families'
        />

        <section>
            <div className='section-info'>
                <Fade direction='left' cascade='true' triggerOnce='true'>
                    <h3 className='section-title'>UNO</h3>
                </Fade>
                <Fade direction='right' cascade='true' triggerOnce='true'>
                    <p className='section-description'>
                        UNO fans are some of the most diehard fans I've ever come across. It's a simple game, but you'd be surprised how seriously people take it. It was fun to play around with the brand and the game itself to make fun and engaging content for their social channels.
                    </p>
                </Fade>
            </div>
                <div className='vertical-gallery'>
                    <Fade direction='top' cascade='true' triggerOnce='true'>
                        {unoGallery}
                    </Fade>
                </div>
        </section>

        <section>
            <div className='section-info'>
                <Fade direction='left' cascade='true' triggerOnce='true'>
                    <h3 className='section-title'>Apples to Apples</h3>
                </Fade>
                <Fade direction='right' cascade='true' triggerOnce='true'>
                    <p className='section-description'>
                        Apples fans ranged from kids to grandparents. They loved random, funny content, which means it was sometimes hard to pinpoint which posts would be successful. In the end, it was always a win if we could make them laugh, so we came up with a few content templates that everybody loved.
                    </p>
                </Fade>
            </div>
                <div className='vertical-gallery'>
                    <Fade direction='top' cascade='true' triggerOnce='true'>
                        {mattelGallery}
                    </Fade>
                </div>
        </section>

        <section>
            <div className='section-info'>
                <Fade direction='left' cascade='true' triggerOnce='true'>
                    <h3 className='section-title'>Scrabble</h3>
                </Fade>
                <Fade direction='right' cascade='true' triggerOnce='true'>
                    <p className='section-description'>
                        Creating content for Scrabble was a fun challenge. Their fans loved any post with Scrabble tiles in it, but if you broke the rules, they were very quick to point out how we had messed up. They also loved to play along in our posts, so it was a fun challenge to always come up with some new way to engage them with nothing but the board and tiles.
                    </p>
                </Fade>
            </div>
            <div className='vertical-gallery'>
                <Fade direction='top' cascade='true' triggerOnce='true'>
                    <img src={scrabble1} alt="coffee beans spread around scrabble tiles spelling out 'but first coffee'"/>
                    <img src={scrabble2} alt="a homemade crafted photo album with scrabble tiles spelling 'cherished memories'"/>
                </Fade>
            </div>
        </section>

        <ProjectNav
            next='/video'
            nextTitle='Video'
        />

    </div>
)