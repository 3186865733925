import React from 'react'
import { Link } from 'react-router-dom'
import { FiArrowRightCircle } from "react-icons/fi";

export default (props) => (
    <div className='project-nav'>
            <Link to={props.next} className='project-nav-next'>
                <h4 className='next-project'>Next Project</h4>
                <FiArrowRightCircle className='next-icon' />
                <h3>{props.nextTitle}</h3>
            </Link>
    </div>
)