import React from 'react';

// COMPONENTS AND STUFF
import Header from '../components/Header'
import MenuItem from '../components/MenuItem'

// IMAGE IMPORTS
import crack from '../images/nav-heroes/crack.jpg'
import trueOrganic from '../images/nav-heroes/true-organic.jpg'
import kfc from '../images/nav-heroes/kfc.jpg'
import calOrganic from '../images/nav-heroes/cal-organic.jpg'
import mattel from '../images/nav-heroes/mattel.jpg'
import video from '../images/nav-heroes/video.jpg'
import code from '../images/nav-heroes/code.jpg'

export default () => (
    <div className='page-wrapper'>
        <Header />
        <div className='menu-wrapper work-menu'>
            <MenuItem
                link='/crack'
                title='Crack'
                src={crack}
            />
            <MenuItem
                link='/true-organic'
                title='True Organic'
                src={trueOrganic}
            />
            <MenuItem
                link='/kfc'
                title='KFC'
                src={kfc}
            />
            <MenuItem
                link='/mattel'
                title='Mattel'
                src={mattel}
            />
            <MenuItem
                link='/cal-organic'
                title='Cal Organic'
                src={calOrganic}
            />
            <MenuItem
                link='/video'
                title='Video'
                src={video}
            />
        </div>
    </div>
)