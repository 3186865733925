import React from 'react'
import { Fade } from 'react-awesome-reveal'

import Header from '../components/Header'
import ProjectIntro from '../components/ProjectIntro'
import ProjectNav from '../components/ProjectNav'

// IMAGE IMPORTS
// HEROS
import trueOrganic from '../images/true-organic/true-organic-hero.jpg'

// SOCIAL
import trueSocial1 from '../images/true-organic/true-social01.jpg'
import trueSocial2 from '../images/true-organic/true-social02.png'
import trueSocial3 from '../images/true-organic/true-social03.jpg'
import trueSocial4 from '../images/true-organic/true-social04.jpg'
import trueSocial5 from '../images/true-organic/true-social05.jpg'
import trueSocial6 from '../images/true-organic/true-social06.jpg'
import trueSocial7 from '../images/true-organic/true-social07.jpg'
import trueSocial8 from '../images/true-organic/true-social08.jpg'
import trueSocial9 from '../images/true-organic/true-social09.png'
import trueSocial10 from '../images/true-organic/true-social10.png'

// MID YEAR RESOLUTIONS
import myr1 from '../images/true-organic/myr1.png'
import myr2 from '../images/true-organic/myr2.png'
import myr3 from '../images/true-organic/myr3.png'
import myr4 from '../images/true-organic/myr4.png'
import myr5 from '../images/true-organic/myr5.png'
import myr6 from '../images/true-organic/myr6.png'
import myr7 from '../images/true-organic/myr7.png'
import myr8 from '../images/true-organic/myr8.png'
import myr9 from '../images/true-organic/myr9.png'
import myr10 from '../images/true-organic/myr10.png'
import myr11 from '../images/true-organic/myr11.png'
import myr12 from '../images/true-organic/myr12.png'

// VEGGIE MUSINGS
import vm1 from '../images/true-organic/veggie-musings-1.jpg'
import vm2 from '../images/true-organic/veggie-musings-2.jpg'
import vm3 from '../images/true-organic/veggie-musings-3.jpg'
import vm4 from '../images/true-organic/veggie-musings-4.jpg'
import vm5 from '../images/true-organic/veggie-musings-5.jpg'
import vm6 from '../images/true-organic/veggie-musings-6.jpg'
import vm7 from '../images/true-organic/veggie-musings-7.jpg'
import vm8 from '../images/true-organic/veggie-musings-8.jpg'
import vm9 from '../images/true-organic/veggie-musings-9.jpg'
import vm10 from '../images/true-organic/veggie-musings-10.jpg'
import vm11 from '../images/true-organic/veggie-musings-11.jpg'

export default () => (
    <div className='page-wrapper'>
        <Header />
        <ProjectIntro
            src={trueOrganic}
            title='True Organic'
            description='TRUE Organic is (was) an organic juice company created by Cal Organic. They arrived a little late to the bottled juice game, so we had to create a unique voice and position for them to stand out in a fairly saturated market. We did this by creating 3 content pillars with various subcategories. TRUE Organic`s USP was rooted in the Cal Organic family, one of the oldest and biggest organic farms in the world. We played off this to create messaging that was as authentic as its parent company, keeping it down to earth and not trying to be anything that it wasn`t.'
        />

        <section>
            <div className='section-info'>
                <Fade direction='left' cascade='true' triggerOnce='true'>
                    <h3 className='section-title'>Mid Year Resolutions</h3>
                </Fade>
                <Fade direction='right' cascade='true' triggerOnce='true'>
                    <p className='section-description'>
                    For the first of TRUE's two yearly digital campaigns, we wanted to help motivate people to remember what they set out to accomplish at the beginning of the year. Our insight played on the fact that by summertime, 92% of new years resolutions have been broken. We called our campaign Mid Year Resolutions, and asked people to share what they wanted to achieve, and encouraged them to get back on the horse and crush their goals this time around. The campaign was a huge success. We saw post engagement increase by 1500%, sharing increased by 500%, and we nearly doubled our follower counts on Instagram and Facebook.
                    </p>
                </Fade>
            </div>
                <div className='vertical-gallery'>
                    <Fade direction='top' cascade='true' triggerOnce='true'>
                        <div className='vertical-gallery-block'>
                            <img src={myr1} alt=""/>
                        </div>
                        <div className='vertical-gallery-block'>
                            <img src={myr2} alt=""/>
                        </div>
                        <div className='vertical-gallery-block'>
                            <img src={myr3} alt=""/>
                        </div>
                        <div className='vertical-gallery-block'>
                            <img src={myr4} alt=""/>
                        </div>
                        <div className='vertical-gallery-block'>
                            <img src={myr5} alt=""/>
                        </div>
                        <div className='vertical-gallery-block'>
                            <img src={myr6} alt=""/>
                        </div>
                        <div className='vertical-gallery-block'>
                            <img src={myr7} alt=""/>
                        </div>
                        <div className='vertical-gallery-block'>
                            <img src={myr8} alt=""/>
                        </div>
                        <div className='vertical-gallery-block'>
                            <img src={myr9} alt=""/>
                        </div>
                        <div className='vertical-gallery-block'>
                            <img src={myr10} alt=""/>
                        </div>
                        <div className='vertical-gallery-block'>
                            <img src={myr11} alt=""/>
                        </div>
                        <div className='vertical-gallery-block'>
                            <img src={myr12} alt=""/>
                        </div>
                    </Fade>
                </div>
        </section>

    <section>
        <div className='section-info'>
            <Fade direction='left' cascade='true' triggerOnce='true'>
                <h3 className='section-title'>Social Content</h3>
            </Fade>
            <Fade direction='right' cascade='true' triggerOnce='true'>
                <p className='section-description'>
                The majority of the day-to-day work on TRUE Organic was for social media posts. We produced a content calendar for them with 20+ posts per month.
                </p>
            </Fade>
        </div>
        <div className='vertical-gallery'>
            <Fade direction='top' cascade='true' triggerOnce='true'>
                <div className='vertical-gallery-block'>
                    <img src={trueSocial1} alt=""/>
                </div>
                <div className='vertical-gallery-block'>
                    <img src={trueSocial2} alt=""/>
                </div>
                <div className='vertical-gallery-block'>
                    <img src={trueSocial3} alt=""/>
                </div>
                <div className='vertical-gallery-block'>
                    <img src={trueSocial4} alt=""/>
                </div>
                <div className='vertical-gallery-block'>
                    <img src={trueSocial5} alt=""/>
                </div>
                <div className='vertical-gallery-block'>
                    <img src={trueSocial6} alt=""/>
                </div>
                <div className='vertical-gallery-block'>
                    <img src={trueSocial7} alt=""/>
                </div>
                <div className='vertical-gallery-block'>
                    <img src={trueSocial8} alt=""/>
                </div>
                <div className='vertical-gallery-block'>
                    <img src={trueSocial9} alt=""/>
                </div>
                <div className='vertical-gallery-block'>
                    <img src={trueSocial10} alt=""/>
                </div>
            </Fade>
        </div>
    </section>

    <section>
        <div className='section-info'>
            <Fade direction='top' cascade='true' triggerOnce='true'>
                <h3 className='section-title'>Veggie Musings</h3>
            </Fade>
            <Fade direction='top' cascade='true' triggerOnce='true'>
                <p className='section-description'>
                After the success of the Mid Year Resolutions campaign, we wanted to create something equally as engaging, but lighten up the mood a bit by adding a little comedy to the mix. My copywriter, Vince Hannah, had an idea that would personify the ingredients and have them talk to our customers, and Veggie Musings was born. Our followers could ask the veggies questions, and get a response. If their question was chosen, they won a juicy prize. We created three distinct characters for three different vegetables that star in almost all of TRUE's juice options.
                </p>
            </Fade>
        </div>
        <div className='vertical-gallery'>
            <Fade direction='top' cascade='true' triggerOnce='true'>
                <div className='vertical-gallery-block'>
                    <img src={vm1} alt=""/>
                </div>
                <div className='vertical-gallery-block'>
                    <img src={vm2} alt=""/>
                </div>
                <div className='vertical-gallery-block'>
                    <img src={vm3} alt=""/>
                </div>
                <div className='vertical-gallery-block'>
                    <img src={vm4} alt=""/>
                </div>
                <div className='vertical-gallery-block'>
                    <img src={vm5} alt=""/>
                </div>
                <div className='vertical-gallery-block'>
                    <img src={vm6} alt=""/>
                </div>
                <div className='vertical-gallery-block'>
                    <img src={vm7} alt=""/>
                </div>
                <div className='vertical-gallery-block'>
                    <img src={vm8} alt=""/>
                </div>
                <div className='vertical-gallery-block'>
                    <img src={vm9} alt=""/>
                </div>
                <div className='vertical-gallery-block'>
                    <img src={vm10} alt=""/>
                </div>
                <div className='vertical-gallery-block'>
                    <img src={vm11} alt=""/>
                </div>
            </Fade>
        </div>
    </section>

    <ProjectNav
        prev='/crack'
        prevTitle='Crack Social'
        next='/kfc'
        nextTitle='KFC'
    />
        
    </div>
)