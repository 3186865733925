import React from 'react'
import { Fade } from 'react-awesome-reveal'

export default (props) => (
    <div className='project-intro' id={props.id}>
        <Fade direction='top' triggerOnce='true' cascade='true' delay={500}>
            <div className='project-info'>
                <div className='title-and-roles'>
                    <h2 className='project-title'>{props.title}</h2>
                    <div className='project-roles'>{props.roles}</div>
                </div>
                <h4 className='project-description'>{props.description}</h4>
            </div>
            <div className='project-hero'>
                <img src={props.src} alt={props.alt} />
            </div>
        </Fade>
    </div>
)