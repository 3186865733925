import React from 'react'
import { Switch } from 'react-router-dom'
import ScrollToTopRoute from './components/ScrollToTopRoute'

import Home from './pages/Home'
import Work from './pages/Work'
import Crack from './pages/Crack'
import KFC from './pages/KFC'
import TrueOrganic from './pages/TrueOrganic'
import Mattel from './pages/Mattel'
import CalOrganic from './pages/CalOrganic'
import Code from './pages/Code'
import Video from './pages/Video'
import About from './pages/About'

import './App.css';

export default () => (
  <div className="app-wrapper">
    <Switch>
        <ScrollToTopRoute exact path="/" component={Home} />
        <ScrollToTopRoute path="/work" component={Work} />
        <ScrollToTopRoute path="/crack" component={Crack} />
        <ScrollToTopRoute path="/kfc" component={KFC} />
        <ScrollToTopRoute path="/true-organic" component={TrueOrganic} />
        <ScrollToTopRoute path="/cal-organic" component={CalOrganic} />
        <ScrollToTopRoute path="/mattel" component={Mattel} />
        <ScrollToTopRoute path="/code" component={Code} />
        <ScrollToTopRoute path="/video" component={Video} />
        <ScrollToTopRoute path="/about" component={About} />
    </Switch>
  </div>
)
